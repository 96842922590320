<template>
  <div>
      {{user}}
  </div>
</template>

<script>
export default {
    data(){
        return {
            user:{},
            url_data:null
        }
    },
 
 mounted(){
     
    this.url_data =this.$route.params.info
    this.user = this.$store.getters.getUser
 }
}
</script>

<style>

</style>